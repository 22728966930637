import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TheHome from "../views/TheHome.vue";
// import AboutUs from "../views/AboutUs.vue";
import ItemListVtuber from "../views/ItemListVtuber.vue";
import TheLogin from "../views/TheLogin.vue";
import SetNewPassword from "../views/SetNewPassword.vue";
import TheMainView from "../views/TheMainView.vue";
import ResetPassword from "../views/ResetPassword.vue";
import TheSignup from "../views/TheSignup.vue";
import MyAccount from "../views/MyAccount.vue";
import EditProfile from "../views/EditProfile.vue";
import EditPaymentInfo from "../views/EditPaymentInfo.vue";
import MyAddress from "../views/MyAddress.vue";
import OrderHistory from "../views/OrderHistory.vue";
import OrderHistoryGoods from "../views/OrderHistoryGoods.vue";
import OrderHistoryTreca from "../views/OrderHistoryTreca.vue";
import OrderHistoryGoodsDetail from "../views/OrderHistoryGoodsDetail.vue";
import OrderHistoryTrecaDetail from "../views/OrderHistoryTrecaDetail.vue";
import CarrierPayment from "../views/CarrierPayment.vue";
import TheTokushoho from "../views/TheTokushoho.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TheCompany from "../views/TheCompany.vue";
import TheTerm from "../views/TheTerm.vue";
import TheInquiry from "../views/TheInquiry.vue";
import TwoFactorAuthentication from "../views/TwoFactorAuthentication.vue";
import TwoFactorAuthenticationDone from "../views/TwoFactorAuthenticationDone.vue";
import BeforeTwoFactorAuthentication from "../views/BeforeTwoFactorAuthentication.vue";
import AnimationHeart from "../components/molecules/AnimationHeart.vue";
import ConfirmSignup from "../views/ConfirmSignup.vue";
import TheFaq from "../views/TheFaq.vue";
import TwoFactorAuthenticationPin from "../views/TwoFactorAuthenticationPin.vue";
import ChangePasswordDone from "../views/ChangePasswordDone.vue";
import TheDocument from "../views/TheDocument.vue";
import TheDocumentTreca from "../views/TheDocumentTreca.vue";
import TheDocumentInvite from "../views/TheDocumentInvite.vue";
import DocumentIr from "../views/DocumentIr.vue";
import ArchiveView from "../views/ArchiveView.vue";
import ArchiveList from "../views/ArchiveList.vue";
import VendorInquiry from "../views/VendorInquiry.vue";
import OutOfHoursPurchase from "../views/OutOfHoursPurchase.vue";

function checkAuthBeforeEnter(to, from, next) {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // eslint-disable-next-line no-param-reassign
      to.meta.user = user;
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  });
}
function checkAuthBeforeEnterandtwofactorauthentication(to, from, next) {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // eslint-disable-next-line no-param-reassign
      to.meta.user = user;

      if (!user.emailVerified) {
        // ユーザーのメールアドレスが未認証の場合、指定のルートにリダイレクト
        next({
          path: "/twofactorauthenticationpin",
          query: { redirect: to.fullPath },
        });
      } else {
        // メールアドレスが認証済みの場合、次のミドルウェア/ルートに進む
        next();
      }
    } else {
      // ユーザーが認証されていない場合、ログインページにリダイレクト
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  });
}

const routes = [
  {
    path: "/",
    name: "home",
    component: TheHome,
    meta: {
      title: "SukiSukiらいぶすてーしょん",
      description:
        "SukiSukiらいぶすてーしょんは、新しい形の「リアルタイムくじ引き」プラットフォームです。",
    },
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: AboutUs,
  // },
  {
    path: "/itemlist_v",
    name: "itemlist_v",
    component: ItemListVtuber,
    meta: {
      title: "商品一覧 - SukiSukiらいぶすてーしょん",
      description: "SukiSukiらいぶすてーしょんの商品一覧です。",
    },
  },
  {
    path: "/login",
    name: "login",
    component: TheLogin,
    meta: {
      title: "ログイン - SukiSukiらいぶすてーしょん",
      description:
        "SukiSukiらいぶすてーしょんのログインページです。アカウントにログインしてサービスをご利用ください。",
    },
  },
  {
    path: "/setnewpassword",
    name: "setnewpassword",
    component: SetNewPassword,
  },
  {
    path: "/main",
    name: "main",
    component: TheMainView,
    beforeEnter: checkAuthBeforeEnterandtwofactorauthentication,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: ResetPassword,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/signup",
    name: "signup",
    component: TheSignup,
    meta: {
      title: "新規登録 - SukiSukiらいぶすてーしょん",
      description:
        "SukiSukiらいぶすてーしょんの新規登録ページです。アカウントを作成してサービスをご利用ください。",
    },
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/myaccount",
    name: "myaccount",
    component: MyAccount,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
    meta: {
      title: "マイアカウント - SukiSukiらいぶすてーしょん",
      description:
        "SukiSukiらいぶすてーしょんのマイアカウントページです。アカウント情報の確認と管理ができます。",
    },
  },
  {
    path: "/editprofile",
    name: "editprofile",
    component: EditProfile,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/editpaymentinfo",
    name: "editpaymentinfo",
    component: EditPaymentInfo,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/myaddress",
    name: "myaddress",
    component: MyAddress,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/orderhistory",
    name: "orderhistory",
    component: OrderHistory,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
    meta: {
      title: "注文履歴 - SukiSukiらいぶすてーしょん",
      description: "SukiSukiらいぶすてーしょんの注文履歴ページです。過去の注文内容を確認できます。",
    },
  },
  {
    path: "/orderhistorygoods",
    name: "orderhistorygoods",
    component: OrderHistoryGoods,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/orderhistorygoodsdetail",
    name: "orderhistorygoodsdetail",
    component: OrderHistoryGoodsDetail,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/orderhistorytreca",
    name: "orderhistorytreca",
    component: OrderHistoryTreca,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/orderhistorytrecadetail",
    name: "orderhistorytrecadetail",
    component: OrderHistoryTrecaDetail,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/carrierpayment",
    name: "carrierpayment",
    component: CarrierPayment,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/archive",
    name: "archive",
    component: ArchiveView,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/archivelist",
    name: "archivelist",
    component: ArchiveList,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/vendorinquiry",
    name: "vendorinquiry",
    component: VendorInquiry,
  },
  //テスト用
  {
    path: "/animationheart",
    name: "animationheart",
    component: AnimationHeart,
  },
  {
    path: "/tokushoho",
    name: "tokushoho",
    component: TheTokushoho,
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/company",
    name: "company",
    component: TheCompany,
  },
  {
    path: "/term",
    name: "term",
    component: TheTerm,
  },
  {
    path: "/inquiry",
    name: "inquiry",
    component: TheInquiry,
    meta: {
      title: "お問い合わせ - SukiSukiらいぶすてーしょん",
      description:
        "SukiSukiらいぶすてーしょんのお問い合わせページです。サービスに関するお問い合わせはこちらから。",
    },
  },
  {
    path: "/twofactorauthentication",
    name: "twofactorauthentication",
    component: TwoFactorAuthentication,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/twofactorauthenticationdone",
    name: "twofactorauthenticationdone",
    component: TwoFactorAuthenticationDone,
  },
  {
    path: "/beforetwofactorauthentication",
    name: "beforetwofactorauthentication",
    component: BeforeTwoFactorAuthentication,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/confirmsignup",
    name: "confirmsignup",
    component: ConfirmSignup,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/faq",
    name: "faq",
    component: TheFaq,
    meta: {
      title: "よくあるご質問 - SukiSukiらいぶすてーしょん",
      description:
        "SukiSukiらいぶすてーしょんのよくあるご質問ページです。サービスに関する質問と回答をご覧いただけます。",
    },
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/twofactorauthenticationpin",
    name: "twofactorauthenticationpin",
    component: TwoFactorAuthenticationPin,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/changepassworddone",
    name: "changepassworddone",
    component: ChangePasswordDone,
  },
  {
    path: "/document",
    name: "document",
    component: TheDocument,
  },
  {
    path: "/documenttreca",
    name: "documenttreca",
    component: TheDocumentTreca,
  },
  {
    path: "/documentinvite",
    name: "documentinvite",
    component: TheDocumentInvite,
  },
  {
    path: "/documentir",
    name: "documentir",
    component: DocumentIr,
  },
  {
    path: "/outofhourspurchase",
    name: "outofhourspurchase",
    component: OutOfHoursPurchase,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // ページ遷移時にスクロール位置をトップにリセット
    return { top: 0 };
  },
});

export default router;
