<template>
  <v-container>
    <div v-if="loadingforsignup" class="loading-overlay">
      <div class="loading-message">お問い合わせ中<span class="loading-dots"></span></div>
    </div>
    <h2 class="mt-3">参加者募集中</h2>
    <p class="mt-6 mb-6 increased-line-height desktop-only">
      SukiSukiらいぶすてーしょんでは、参加者を募集しております。<br />
      vtuber様、ライバー様、歌い手様、ゲーム配信者様、アイドル事務所様、芸能事務所様等お気軽にご連絡ください。<br />
      グッズの企画から商品発送まで代行いたします。ご参加は無料です。
    </p>

    <p class="mt-6 mb-6 increased-line-height mobile-only">
      SukiSukiらいぶすてーしょんでは、<br />
      参加者を募集しております。<br />
      vtuber様、ライバー様、歌い手様、<br />
      ゲーム配信者様、アイドル事務所様、<br />
      芸能事務所様等お気軽にご連絡ください。<br />
      グッズの企画から商品発送まで代行いたします。<br />
      ご参加は無料です。
    </p>
    <v-img
      src="https://storage.googleapis.com/rf-prd-sukisuki-ls.appspot.com/live_station_images_common/vd.png"
    ></v-img>
  </v-container>
  <v-container>
    <h3 class="mt-8 mb-8">お問い合わせ・資料ダウンロード</h3>
    <v-form ref="form" v-model="valid">
      <p class="text-left ml-2 mt-7 title">お名前<span class="required-label">※必須</span></p>
      <div class="d-flex align-center">
        <v-text-field
          v-model="name"
          :rules="[(v) => !!v || '名前は必須です']"
          required
        ></v-text-field>
      </div>

      <p class="text-left ml-2 mt-3 title">会社名</p>
      <v-text-field v-model="company"></v-text-field>

      <p class="text-left ml-2 mt-3 title">
        メールアドレス<span class="required-label">※必須</span>
      </p>
      <div class="d-flex align-center">
        <v-text-field v-model="email" :rules="emailRules" required></v-text-field>
      </div>

      <p class="text-left ml-2 mt-3 title">X (Twitter) アカウント名</p>
      <div class="d-flex align-center">
        <v-text-field v-model="twitter"></v-text-field>
      </div>

      <p class="text-left ml-2 mt-3 title">紹介コード</p>
      <v-text-field v-model="referralCode"></v-text-field>

      <p class="text-left ml-2 mt-3 title">
        お問い合わせ内容<span class="required-label">※必須</span>
      </p>
      <v-radio-group
        v-model="inquiryType"
        :rules="[(v) => !!v || 'お問い合わせ内容は必須です']"
        required
      >
        <v-radio label="参加したい" value="参加したい"></v-radio>
        <v-radio label="詳細を聞いてみたい" value="詳細を聞いてみたい"></v-radio>
        <v-radio label="その他" value="その他"></v-radio>
      </v-radio-group>

      <p class="text-left ml-2 mt-3 title">ご質問・お問い合わせ</p>
      <v-textarea v-model="details"></v-textarea>

      <v-btn class="mt-5 addbobtn" height="40" width="350" rounded="pill" @click="submitForm">
        <a class="addpayment">資料をダウンロードして問い合わせる</a>
      </v-btn>
    </v-form>
    <p class="mt-6 desktop-only">参加には所定の審査がございます。まずはお気軽にご連絡ください。</p>
    <p class="mt-6 increased-line-height mobile-only">
      参加には所定の審査がございます。<br />まずはお気軽にご連絡ください。
    </p>
  </v-container>
  <v-container v-if="showSuccessMessage">
    <p>
      お問い合わせが完了いたしました。3日以内に担当よりご返信いたします。<br />
      資料は
      <a :href="materialUrl" target="_blank" rel="noopener noreferrer" class="underlined">こちら</a>
      よりダウンロードいただけます。
    </p>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRoute } from "vue-router";

const route = useRoute();
const name = ref("");
const company = ref("");
const email = ref("");
const twitter = ref("");
const referralCode = ref("");
const inquiryType = ref("");
const details = ref("");
const valid = ref(true);
const materialUrl = ref(
  "https://firebasestorage.googleapis.com/v0/b/rf-prd-sukisuki-ls.appspot.com/o/live_station_images_common%2FSukiSuki%E3%82%89%E3%81%84%E3%81%B5%E3%82%99%E3%81%99%E3%81%A6%E3%83%BC%E3%81%97%E3%82%87%E3%82%93%E3%81%93%E3%82%99%E7%B4%B9%E4%BB%8B%E8%B3%87%E6%96%99.pdf?alt=media&token=c78006a7-7e22-4b93-8885-31a73ea57708"
);
const loadingforsignup = ref(false);
const source = ref("");
const emailRules = [
  (v) => !!v || "メールアドレスを入力してください",
  (v) => /.+@.+\..+/.test(v) || "正しいメールアドレスを入力してください",
];

// URLパラメータから広告元を取得
onMounted(() => {
  const sourceParam = route.query.source;
  if (sourceParam) {
    source.value = sourceParam;
  }
});

async function submitForm() {
  loadingforsignup.value = true;
  console.log(name.value);
  console.log(company.value);
  console.log(email.value);
  console.log(twitter.value);
  console.log(referralCode.value);
  console.log(inquiryType.value);
  console.log(details.value);
  console.log(source.value);
  if (valid.value) {
    const functions = getFunctions();
    functions.region = "asia-northeast1";
    const commonContactMail = httpsCallable(functions, "Vendor_contactMail");
    try {
      const result = await commonContactMail({
        name: name.value,
        company: company.value,
        email: email.value,
        twitter: twitter.value,
        referralCode: referralCode.value,
        inquiryType: inquiryType.value,
        details: details.value,
        source: source.value,
      });
      console.log(result);
      loadingforsignup.value = false;
      showSuccessMessage.value = true;
    } catch (error) {
      loadingforsignup.value = false;
      console.error("Error calling function:", error);
    }
  } else {
    loadingforsignup.value = false;
    console.log("Form is invalid");
  }
}
const showSuccessMessage = ref(false);
</script>

<style scoped>
.addbobtn {
  background-color: rgb(237, 121, 149);
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
.addpayment {
  color: rgb(255, 255, 255);
  font-size: 16px;
}
.required-label {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
.title {
  font-size: 18px;
}
.increased-line-height {
  line-height: 1.3; /* ここで行間を調整 */
}
.underlined {
  text-decoration: underline;
}

.desktop-only {
  display: none;
}
.mobile-only {
  display: block;
}
@media (min-width: 960px) {
  .desktop-only {
    display: block;
  }
  .mobile-only {
    display: none;
  }
}

.loading-message {
  color: white; /* 白色の文字色 */
  font-size: 24px;
  font-weight: bold;
}

.loading-dots::after {
  content: "";
  display: inline-block;
  width: 1em;
  animation: ellipsis 2s infinite;
  color: white; /* 白色の文字色 */
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5); /* 半透明の灰色背景色 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* 追加：z-indexプロパティ */
}
</style>
