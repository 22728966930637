<template>
  <div>
    <div v-if="isPdfLoading" class="loading">資料を読み込み中...</div>
    <div v-if="loadError" class="error">
      {{ errorMessage }}
      <button @click="reloadPage" class="reload-button">ページを更新</button>
    </div>
    <iframe
      :src="pdfViewerUrl"
      width="100%"
      height="900px"
      style="border: none"
      @load="handleIframeLoad"
      @error="handleIframeError"
    ></iframe>
    <!-- フォールバックとしてPDFダウンロードリンク -->
    <p v-if="!isPdfSupported" style="margin-top: 30px">
      SukiSukiらいぶすてーしょんの資料は
      <a :href="pdfUrl" target="_blank" rel="noopener noreferrer">こちら</a
      >からダウンロードしてください。
    </p>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";

// PDFの読み込み状態を管理
const isPdfLoading = ref(true);
const loadError = ref(false);
const errorMessage = ref("");

// URLからクエリパラメータを取得する関数
const getQueryParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

// Firebase Functionsを呼び出す関数
const result = ref(null);
const callFunction = async (documentId) => {
  try {
    const functions = getFunctions(undefined, "asia-northeast1");
    const getDocument = httpsCallable(functions, "sendToEngage");

    const response = await getDocument({ id: documentId });

    // 結果をそのまま表示
    result.value = response.data;
    console.log("Firebase Functions レスポンス:", response.data);
  } catch (err) {
    console.error("Firebase Functions エラー:", err);
    result.value = { error: err.message };
  }
};

// URLからIDを取得
const documentId = getQueryParam("id");

// PDFファイルのURL
const pdfUrl = ref(
  "https://storage.googleapis.com/rf-prd-sukisuki-ls.appspot.com/live_station_images_common/%E3%83%AA%E3%82%A2%E3%83%AB%E3%82%BF%E3%82%A4%E3%83%A0%E3%81%8F%E3%81%97%E3%82%99%E5%BC%95%E3%81%8D%E3%80%8CSukiSuki%E3%82%89%E3%81%84%E3%81%B5%E3%82%99%E3%81%99%E3%81%A6%E3%83%BC%E3%81%97%E3%82%87%E3%82%93%E3%80%8D%E8%B3%87%E6%96%99.pdf"
);

// PDFビューアー用のURL
const pdfViewerUrl = ref(
  `https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl.value)}&embedded=true`
);

// iframeの読み込み完了を処理
const handleIframeLoad = () => {
  isPdfLoading.value = false;
  loadError.value = false;
  errorMessage.value = "";
};

// iframeの読み込みエラーを処理
const handleIframeError = () => {
  isPdfLoading.value = false;
  loadError.value = true;
  errorMessage.value = "資料の読み込みに失敗しました。ページを更新してください。";
  console.error("PDFの読み込みに失敗しました");
};

// タイムアウト処理
let timeoutId;
const startTimeout = () => {
  timeoutId = setTimeout(() => {
    if (isPdfLoading.value) {
      isPdfLoading.value = false;
      loadError.value = true;
      errorMessage.value = "資料の読み込みに失敗しました。ページを更新してください。";
      console.error("PDFの読み込みがタイムアウトしました");
    }
  }, 30000); // 30秒
};

// ページを更新するメソッド
const reloadPage = () => {
  window.location.reload();
};

// コンポーネントのマウント時に実行
onMounted(() => {
  startTimeout();
  // IDが存在する場合のみ関数を実行
  if (documentId) {
    callFunction(documentId);
  } else {
    console.log("URLにIDが指定されていないため、処理をスキップします");
  }
});

// コンポーネントのアンマウント時に実行
onUnmounted(() => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});

// ブラウザがネイティブのPDF表示をサポートしているか確認
const isPdfSupported = ref(
  (() => {
    const canvas = document.createElement("canvas");
    return !!(canvas.getContext && canvas.getContext("2d"));
  })()
);
</script>

<style scoped>
.loading {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #666;
}

.error {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #ff4444;
  background-color: #fff5f5;
  border-radius: 4px;
  margin: 10px 0;
}

.reload-button {
  display: block;
  margin: 10px auto;
  padding: 8px 16px;
  background-color: #f0acc4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.reload-button:hover {
  background-color: #e89db3;
}
</style>
