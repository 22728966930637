import { watchEffect } from "vue";
import { useRoute } from "vue-router";

export function useMeta() {
  const route = useRoute();

  watchEffect(() => {
    // タイトルの設定
    if (route.meta.title) {
      document.title = route.meta.title;
    }

    // メタディスクリプションの設定
    if (route.meta.description) {
      let desc = document.querySelector('meta[name="description"]');
      if (!desc) {
        desc = document.createElement("meta");
        desc.name = "description";
        document.head.appendChild(desc);
      }
      desc.content = route.meta.description;
    }

    // OGPタグの設定
    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      const newOgTitle = document.createElement("meta");
      newOgTitle.setAttribute("property", "og:title");
      document.head.appendChild(newOgTitle);
    }
    document.querySelector('meta[property="og:title"]').content =
      route.meta.title || document.title;

    const ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      const newOgDescription = document.createElement("meta");
      newOgDescription.setAttribute("property", "og:description");
      document.head.appendChild(newOgDescription);
    }
    document.querySelector('meta[property="og:description"]').content =
      route.meta.description || "";

    const ogImage = document.querySelector('meta[property="og:image"]');
    if (route.meta.image && !ogImage) {
      const newOgImage = document.createElement("meta");
      newOgImage.setAttribute("property", "og:image");
      document.head.appendChild(newOgImage);
    }
    if (route.meta.image) {
      document.querySelector('meta[property="og:image"]').content = route.meta.image;
    }
  });
}
